export type STATUS = typeof LOADING | typeof ERROR | typeof DONE;

export const MENU_STATE = {
  OPEN: 'open',
  CLOSED: 'closed',
};

export const LOADING = 'LOADING';
export const ERROR = 'ERROR';
export const DONE = 'DONE';
export const DESKTOP_PLATFORMS = ["chrome", "edge", "firefox"];
export const MOBILE_PLATFORMS = ["android", "ios"];



export const ROUTES = {
  LOGIN: '/',
  HOME: '/execute-feature',
  EXECUTE: '/execute-feature',
  HISTORY: '/history',
  REPORT: 'execute-feature/execution-report',
  FEATURE: '/feature-report',
  SETTINGS: '/settings',
  JOURNEYS: '/journeys',
  FEATUREFILES: '/featurefiles',
  LINK: '/link',
  VIEWEXECUTE: '/view-execute'
};


