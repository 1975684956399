import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env';
import { AuthService } from '@auth/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in
    const token = this.auth.getToken();
    const isLoggedIn = !!token;
    const refresh = this.auth.getRefreshToken();
    const isApiUrl = request.url.startsWith(environment.baseurl);
    if (isLoggedIn) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          // 'x-refresh': refresh,
        },
      });
    }

    return next.handle(request);
  }
}
