import { environment } from '@env';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { CUSTOMER_ID_KEY, PROJECT_CODE_KEY, PROJECT_ID_KEY, PROJECT_LIST_KEY, PROJECT_LOGO_KEY, PROJECT_NAME_KEY, USER_ID } from '@core/constants/local-storage-keys.constants';
import { USER_EMAIL_KEY, USER_FULLNAME_KEY, USER_REFRESH_TOKEN, USER_TOKEN } from '@core/constants/local-storage-keys.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  BASE_URL = environment.baseurl;
  token: string | null;
  name: string | null;
  userInfo: any;

  constructor(
    private http: HttpClient) {
    this.token = localStorage.getItem(USER_TOKEN);
    this.name = localStorage.getItem(USER_FULLNAME_KEY)
    this.userInfo = new BehaviorSubject({});
  }
  httpOptions: any = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Headers': 'Content-Type',
      'Access-Control-Allow-Origin': '*'
    })
  };

  login(userCredential: IUserCredentials) {
    return this.http.post(`${this.BASE_URL}/login`, userCredential).pipe(
      tap((response) => {
        this.setToken(response);
        this.setProjectInfo(response);
        this.setUser(response);
        

      })
    );
  }

  getUserName() {
    return localStorage.getItem(USER_FULLNAME_KEY);
  }
  
  getUserEmail() {

    return localStorage.getItem(USER_EMAIL_KEY);

  }

  setUser(response: any) {
    this.userInfo.next(response);
    localStorage.setItem(USER_FULLNAME_KEY, response.data.userInfo.su_fullname);
    localStorage.setItem(USER_EMAIL_KEY, response.data.userInfo.su_email);

  }

  setToken(response: any) {
    this.token = response.data.token;
    localStorage.setItem(USER_TOKEN, response.data.token);
    localStorage.setItem(USER_REFRESH_TOKEN, response.data.refreshToken);
  }

  getToken(): string | null {

    return localStorage.getItem(USER_TOKEN);
  }
  
  setProjectInfo(response: any) {
    var id = response.data.userInfo.su_projects[0].sp_id;
    localStorage.setItem(PROJECT_ID_KEY, id);
    localStorage.setItem(PROJECT_LIST_KEY, JSON.stringify(response.data.userInfo.su_projects));
    localStorage.setItem(PROJECT_CODE_KEY, response.data.userInfo.su_projects[0].sp_project_code)
    localStorage.setItem(PROJECT_NAME_KEY, response.data.userInfo.su_projects[0].sp_project_name)
    localStorage.setItem(PROJECT_LOGO_KEY, response.data.userInfo.su_projects[0].sp_project_logo)
    localStorage.setItem(CUSTOMER_ID_KEY, response.data.userInfo.su_projects[0].sc_id)
    localStorage.setItem(USER_ID, response.data.userInfo.su_id)   
  }
  
  getRefreshToken(): string {
    return localStorage.getItem(USER_REFRESH_TOKEN) || '';
  }

  logout() {
    localStorage.removeItem(PROJECT_ID_KEY)
    localStorage.clear();
    this.token = null;
    this.userInfo.next(null)
  }

}
export interface IUserCredentials {
  su_username: string;
  su_password: string;
}
export interface ISessionResponse {
  accessToken: string;
  refreshToken: string;
}
