const apiurl = 'https://lcxicrka9a.execute-api.eu-west-2.amazonaws.com/tfg'

export const environment = {
  baseurl:'https://qakzb9cch8.execute-api.eu-west-2.amazonaws.com/tfg/v1',
  production: true,
  executionAPI: 'https://v8xnsq517b.execute-api.eu-west-2.amazonaws.com/tfg/executeTest',
  journeyAPI: apiurl+'/v1/journey',
  // journey api without pagination
  allJourneyAPI: apiurl+'/journey',
  historyAPI: apiurl+'/execution-report-history',
  reportAPI: apiurl+'/execution-report',
  projectAPI: 'https://ppm7pp5cti.execute-api.eu-west-2.amazonaws.com/tfg/project',

  // needs to be updated with the exact URL 
  agentAPI: 'https://ppm7pp5cti.execute-api.eu-west-2.amazonaws.com/tfg/agents',

  imageRepoURL: 'https://ecast-client.s3.eu-west-2.amazonaws.com',
  screenshotRepoURL: 'https://ecast-tfg-s3.s3.eu-west-2.amazonaws.com',
  getIpAddressAPI: 'https://ipinfo.io/json',
  downloadFiles: 'http://localhost:3000/development/download-featurefile',
  createPage: apiurl+'/sitemap',
  getPageWithoutJourney:  apiurl+"/pages",
  deletePage: apiurl+"/sitemap-delete",
  updateProject: apiurl+"/project/update",
  stopExecutionApi: apiurl + "/stop-execution",
  getJourneyTags: apiurl + "/journeytags",
  viewExecute: apiurl+'/current-execution',
  executionNameAPI: apiurl+'/current-execution',

};
