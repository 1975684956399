import { AfterViewInit, Component, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { ROUTES } from '@core/constants/constants'
import { PROJECT_CODE_KEY, PROJECT_ID_KEY, PROJECT_LIST_KEY, PROJECT_LOGO_KEY, PROJECT_NAME_KEY, USER_EMAIL_KEY, USER_FULLNAME_KEY } from '@core/constants/local-storage-keys.constants';
import { environment } from '@env';
import { CardService } from '../../../modules/stage4-execute/services/card.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  show: boolean = false;
  list2: any;
  label2 = "Desktop Browsers"
  public projectData: any;
  projectName: any
  projectLogo: any;
  name: any;
  email: any;
  is_loggedin: boolean = false;
  projectList: any | undefined = [];
  isMultiProject: boolean = this.projectList.length>1;

  constructor(
    public _authService: AuthService,
    private _router: Router,
    private cardService: CardService,

  ) {

  }

  ngOnInit(): void {

    this.is_loggedin = false;
   
    this._authService.userInfo.subscribe((user: any) => {
      if (user == null) {
        this.is_loggedin = false;
      }
      else {
        if (Object.keys(user).length == 0) {
          this.name = localStorage.getItem(USER_FULLNAME_KEY);
          this.email = localStorage.getItem(USER_EMAIL_KEY);

          this.projectName = localStorage.getItem(PROJECT_NAME_KEY);
          this.projectLogo = environment.imageRepoURL + '/' + localStorage.getItem(PROJECT_LOGO_KEY);
          
          var projectList = JSON.parse(localStorage.getItem(PROJECT_LIST_KEY) || '[]');
          if(projectList){
            this.projectList = projectList ;
          }
        }
        else {

          this.name = user.data.userInfo.su_fullname;
          this.email = user.data.userInfo.su_email

          this.projectList = user.data.userInfo.su_projects;
          this.projectName = user.data.userInfo.su_projects[0].sp_project_name;
          this.projectLogo = environment.imageRepoURL + '/' + user.data.userInfo.su_projects[0].sp_project_logo;
          
        }

        if (!!this._authService.getToken()) {
          console.log('is token present', !!this._authService.getToken(), 'setting true')
          this.is_loggedin = true;
        }

        this.isMultiProject = this.projectList.length > 1;
      }



    }
    );

  }

  showDetails() {
    this.show = true;
  }

  closeUserPopup() {
    this.show = false
  }

  signout() {
    this._authService.logout();
    this.is_loggedin = false;
    this._router.navigateByUrl('/');
  }
  goToHome() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigateByUrl(ROUTES.HOME);
  }

  gotoExecute() {
    this._router.navigateByUrl(ROUTES.EXECUTE).then()
  }

  gotoHistory() {
    this._router.navigateByUrl(ROUTES.EXECUTE + ROUTES.HISTORY)
  }

  switchProject(project:any){

    if(this.projectName != project.sp_project_name)
    {
      localStorage.setItem(PROJECT_ID_KEY, project.sp_id);
      localStorage.setItem(PROJECT_NAME_KEY, project.sp_project_name);
      localStorage.setItem(PROJECT_LOGO_KEY, project.sp_project_logo);
      localStorage.setItem(PROJECT_CODE_KEY, project.sp_project_code);
      this._router.navigateByUrl(ROUTES.HOME).then(() => {
        window.location.reload();
      });
    }
    }
   


}
