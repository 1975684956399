import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '@auth/auth.service';
import { NotifierService } from '@core/utils/notifier.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private notifier: NotifierService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if ([401, 403].includes(err.status) && this.authService.getToken()) {
          // auto logout if 401 or 403 response returned from api
          this.authService.logout();
          this.notifier.error(
            'Unauthorized!',
            'Kindly login to proceed.'
          );
          this.router.navigate(['/']);
        }

        const messages = err.error?.status?.message
          ? JSON.parse(err.error.status?.message)
          : '';
        const message = messages[0]?.msg || err.statusText;

        return throwError(message);
      })
    );
  }
}
