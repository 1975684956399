import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from '@shared/shared.module';

import { CoreModule } from '@core/core.module';
import { ChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    CoreModule,
    ChartsModule,

  ],
  providers: [
 
 ],
  bootstrap: [AppComponent],
})
export class AppModule { }
