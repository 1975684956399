import { LoginComponent } from '@auth/login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { HistoryComponent } from './modules/stage4-execute/components/history/history.component';
import { AuthGuard } from '@core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'execute-feature',
    loadChildren: () => import('./modules/stage4-execute/stage4-execute.module').then((m) => m.Stage4ExecuteModule),
  },
  // {
  //   path: 'history',
  //   component: HistoryComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'execution-report',
  //   loadChildren: () => import('./execution-report/execution-report.module').then((m) => m.ExecutionReportModule),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
