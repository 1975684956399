<div class="nav" id="navbar" *ngIf="is_loggedin">

    <div class="navbar-menu-left">
        <div class="logo">
            <img class="logo1" (click)="goToHome()" src="../../../assets/images/ecastLogo1.png">
        </div>

        <!-- No longer required since there is only one menu item-->
        <!-- Execute Menu  -->
        <!-- <div class="menu-item active">
            Execute
            <span class="pd-10"></span>
            <i class="fa fa-caret-down"></i> -->
            <!-- Menu dropdown -->
            <!--
            <div class="dropdown">
                <button (click)="gotoExecute()" class="dropdownButton">Execute Features</button>
                <button (click)="gotoHistory()" class="dropdownButton">View History</button>
            </div>
        </div> -->

    </div>

    <div class="navbar-menu-right">
        <!-- user menu -->
        <div class="avatar">
            <img class="avatar" src="../../../assets/images/avatar.png">

            <div class="avatar-dropdown">

                <div class="dropdown-project-info">
                    <div><img id="logo" [src]="projectLogo"></div>
                    <p class="ellipsify-text">{{projectName}}</p>
                </div>

                <div class="dropdown-user-info">
                    <div class="user-name">{{name}}</div>
                    <div class="user-email">{{email}}</div>
                </div>

                <div class="dropdown-link">
                    <div><img id="menu-icon" src="../../../assets/images/signOutIcon.svg"></div>
                    <div><button class="profile-dropdown-item-btn" (click)="signout()">SignOut</button></div>
                </div>

            </div>
        </div>
        <!-- project logo and name -->
        <div class="project-menu">
            <div class="logo-outer-div" [ngClass]="{'active' : isMultiProject}">
                <div id="project-logo-img" [ngClass]="{'active' : isMultiProject}"><img [src]="projectLogo"></div>
                <div id="project-logo-name" [ngClass]="{'active' : isMultiProject}">
                    <p>{{projectName}}</p>
                </div>

                <div class="project-dropdown" *ngIf="projectList && projectList.length>1">
                    <ng-container *ngFor="let project of projectList">
                        <!--  [ngClass] = "{'selected' : project.sp_project_name == projectName}"  -->
                        <div class="project-item">
                            <div class="select-icon" [ngClass] = "{'selected' : project.sp_project_name == projectName}">
                                <!-- <ng-container *ngIf="project.sp_project_name == projectName"> -->
                                    <i class="fa fa-check" aria-hidden="true"></i>

                                <!-- </ng-container> -->
                            </div>
                            <button (click)="switchProject(project)" class="project-btn">
                                
                                <div class="btn-txt" style=" overflow: hidden;
                               
                                text-overflow: ellipsis;">{{project.sp_project_name}}</div>
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>

        </div>
    </div>


</div>